import React, { useContext } from 'react';
import * as classNames from 'classnames';

import { mediaTypeContext } from './Layout';
import * as styles from './Frame.module.scss';

interface Props {
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

const Frame: React.FC<Props> = ({
  className,
  headerClassName,
  bodyClassName,
  footerClassName,
  header,
  children,
  footer,
}) => {
  const mediaType = useContext(mediaTypeContext);
  const headerIsEmpty = !header || !React.Children.count(header);
  return (
    <div className={classNames(styles.frame, styles[mediaType], className)}>
      <div
        className={classNames(styles.header, { [styles.empty]: headerIsEmpty }, headerClassName)}>
        {header}
      </div>
      <div className={classNames(styles.body, bodyClassName)}>{children}</div>
      <div className={classNames(styles.footer, footerClassName)}>{footer}</div>
    </div>
  );
};

export default Frame;
