import React, { useContext } from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import rehypeReact from 'rehype-react';
import icon from '@devsisters/ui-common/lib/icon';
import { DevsistersCi } from '@devsisters/ui-common/lib/identity';

import { useClipboard, getAvatarImage } from '../util';
import { mediaTypeContext } from './Layout';
import Frame from './Frame';
import WeAreHiring from './post/WeAreHiring';
import Youtube from './post/Youtube';
import * as styles from './Post.module.scss';

interface PostProps {
  data: {
    markdownRemark: Gatsby.Node;
    avatars: {
      edges: { node: Avatar }[];
    };
  };
  pathContext: { prev: Post | null; next: Post | null };
  location: Location;
}

interface Avatar {
  id: string;
  filename: string;
}

interface Author {
  displayName: string;
  email: string;
}

interface Post {
  frontmatter: { title: string };
  fields: { path: string };
}

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    'we-are-hiring': WeAreHiring,
    youtube: Youtube,
  },
}).Compiler;

// this prop will be injected by the GraphQL query we'll write in a bit
const Post: React.FC<PostProps> = ({ data, pathContext, location }) => {
  const title = data.markdownRemark.frontmatter.title;
  const description = data.markdownRemark.frontmatter.summary;
  const date = data.markdownRemark.fields.date;
  const htmlAst = data.markdownRemark.htmlAst;
  const url = location.href;
  const authors: Author[] = data.markdownRemark.fields.authors;
  const avatars = data.avatars.edges.map(edge => edge.node);
  const text = title;
  const via = 'devsisters';
  const hashtags = ['데브시스터즈', '기술블로그', 'DevTech'];
  const [$url, $text, $via] = [url, text, via].map(encodeURIComponent);
  const $hashtagsString = encodeURIComponent(hashtags.join(','));
  const { prev, next } = pathContext;
  const mediaType = useContext(mediaTypeContext);
  const isPc = mediaType === 'pc';
  const clipboard = useClipboard<HTMLButtonElement>(url, '포스트 주소가 복사되었습니다.');
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
      </Helmet>
      <Frame
        bodyClassName={classNames(styles.frameBody, styles[mediaType])}
        footerClassName={classNames(styles.frameFooter, styles[mediaType])}
        footer={
          isPc ? (
            <PcFrameFooter prev={prev} next={next} />
          ) : (
            <MobileFrameFooter prev={prev} next={next} />
          )
        }>
        {isPc ? (
          <PcHeader avatars={avatars} authors={authors} title={title} date={date} />
        ) : (
          <MobileHeader avatars={avatars} authors={authors} title={title} date={date} />
        )}
        <article id="article" className={classNames(styles.container, mediaType)}>
          {renderAst(htmlAst)}
        </article>
        <footer className={classNames(styles.footer, styles[mediaType])}>
          <DevsistersCi className={styles.logo} />
          <div className={styles.snsLinks}>
            <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${$url}`}>
              <FacebookIcon />
            </a>
            <a
              target="_blank"
              href={`https://twitter.com/intent/tweet?url=${$url}&text=${$text}&hashtags=${$hashtagsString}&via=${$via}`}>
              <TwitterIcon />
            </a>
            <button ref={clipboard}>
              <LinkIcon />
            </button>
          </div>
        </footer>
      </Frame>
    </>
  );
};

export default Post;

export const pageQuery = graphql`
  query($originalPath: String!) {
    markdownRemark(fields: { path: { eq: $originalPath } }) {
      htmlAst
      frontmatter {
        title
        summary
      }
      fields {
        date
        authors {
          author
          displayName
          email
        }
      }
    }
    avatars: allFile(filter: { sourceInstanceName: { eq: "author-avatars" } }) {
      edges {
        node {
          id: name
          filename: base
        }
      }
    }
  }
`;

interface HeaderProps {
  avatars: Avatar[];
  authors: Author[];
  title: string;
  date: string;
}
const PcHeader: React.FC<HeaderProps> = ({ avatars, authors, title, date }) => {
  return (
    <header className={classNames(styles.header, styles.pc)}>
      <img
        src={getAvatarImage(
          avatars,
          authors.map(author => author.email)
        )}
      />
      <h1>{title}</h1>
      <time dateTime={date}>{date}</time>
      <span className={styles.author}>
        {authors.map((author: any) => author.displayName).join(', ')}
      </span>
    </header>
  );
};

const MobileHeader: React.FC<HeaderProps> = ({ avatars, authors, title, date }) => {
  return (
    <header className={classNames(styles.header, styles.mobile)}>
      <h1>{title}</h1>
      <img
        src={getAvatarImage(
          avatars,
          authors.map(author => author.email)
        )}
      />
      <div>
        <time dateTime={date}>{date}</time>
        <span className={styles.author}>
          {authors.map((author: any) => author.displayName).join(', ')}
        </span>
      </div>
    </header>
  );
};

const FacebookIcon = icon(
  '0 0 32 32',
  <path
    className={styles.facebook}
    d="M30.23,0H1.77C0.79,0,0,0.79,0,1.77v28.47C0,31.21,0.79,32,1.77,32h15.33V19.62h-4.16v-4.84h4.16v-3.56c0-4.13,2.52-6.38,6.21-6.38c1.77,0,3.28,0.13,3.73,0.19v4.32l-2.54,0c-2.01,0-2.39,0.95-2.39,2.35v3.09h4.8l-0.62,4.84h-4.17V32h8.14c0.98,0,1.77-0.79,1.77-1.77V1.77C32,0.79,31.21,0,30.23,0z"
  />
);

const TwitterIcon = icon(
  '0 0 32 32',
  <path
    className={styles.twitter}
    d="M27.99,0H4.01C1.8,0,0,1.8,0,4.01v23.98C0,30.2,1.8,32,4.01,32h23.97C30.2,32,32,30.2,32,27.99V4.01C32,1.8,30.2,0,27.99,0z M23.98,11.91c0.01,0.18,0.01,0.35,0.01,0.53c0,5.44-4.14,11.71-11.71,11.71c-2.32,0-4.49-0.68-6.31-1.85c0.32,0.04,0.65,0.06,0.98,0.06c1.93,0,3.7-0.66,5.11-1.76c-1.8-0.03-3.32-1.22-3.85-2.86c0.25,0.05,0.51,0.07,0.77,0.07c0.38,0,0.74-0.05,1.09-0.14c-1.88-0.38-3.3-2.04-3.3-4.04c0-0.02,0-0.04,0-0.05c0.55,0.31,1.19,0.49,1.86,0.51c-1.1-0.74-1.83-2-1.83-3.43c0-0.75,0.2-1.46,0.56-2.07c2.03,2.49,5.06,4.13,8.48,4.3c-0.07-0.3-0.11-0.62-0.11-0.94c0-2.27,1.84-4.12,4.12-4.12c1.18,0,2.25,0.5,3,1.3c0.94-0.18,1.82-0.53,2.61-1c-0.31,0.96-0.96,1.77-1.81,2.28c0.83-0.1,1.63-0.32,2.36-0.65C25.48,10.6,24.78,11.33,23.98,11.91z"
  />
);

const LinkIcon = icon(
  '0 0 32 32',
  <path
    className={styles.link}
    d="M27.99,0H4.01C1.8,0,0,1.8,0,4.01v23.98C0,30.2,1.8,32,4.01,32h23.97C30.2,32,32,30.2,32,27.99V4.01C32,1.8,30.2,0,27.99,0z M14.17,26.47l-1.57,1.57l-1.57-1.57L3.96,19.4l1.57-1.57l5.5-5.5l1.57,1.57l-5.5,5.5l5.5,5.5l5.5-5.5l1.57,1.57L14.17,26.47L14.17,26.47z M18.44,12.03l1.53,1.53l-6.89,6.89l-1.53-1.53L18.44,12.03z M20.98,19.66l-1.57-1.57l5.5-5.5l-5.5-5.5l-5.5,5.5l-1.57-1.57l5.5-5.5l1.57-1.57l1.57,1.57l5.5,5.5l1.57,1.57L20.98,19.66z"
  />
);

interface FrameFooterProps {
  prev: Post | null;
  next: Post | null;
}
const PcFrameFooter: React.FC<FrameFooterProps> = ({ prev, next }) => {
  return (
    <>
      <div>
        {prev && <Link to={prev.fields.path}>＜ {prev.frontmatter.title}</Link>}
        {next && <Link to={next.fields.path}>＞ {next.frontmatter.title}</Link>}
      </div>
      <div>
        <Link to="/">목록으로 돌아가기 ＞＞</Link>
      </div>
    </>
  );
};
const MobileFrameFooter: React.FC<FrameFooterProps> = ({ prev, next }) => {
  return (
    <div className={styles.wrap}>
      <div>
        {prev && <Link to={prev.fields.path}>이전</Link>}
        {next && <Link to={next.fields.path}>다음</Link>}
      </div>
      <div>
        <Link to="/">목록</Link>
      </div>
    </div>
  );
};
