import React, { useContext } from 'react';
import classNames from 'classnames';

import { mediaTypeContext } from '../Layout';
import * as styles from './WeAreHiring.module.scss';

interface Props {
  class?: string;
  title?: string;
  link: string;
  tags?: string;
}

const WeAreHiring: React.FC<Props> = (props: Props) => {
  const mediaType = useContext(mediaTypeContext);
  const [titleArray, linkArray] = React.useMemo(() => {
    const titleArray = props.title.split(',').slice(0, 3);
    const linkArray = props.link.split(',').slice(0, 3);
    return [titleArray, linkArray];
  }, [props.title, props.link]);
  return (
    <div className={classNames(styles.weAreHiring, styles[mediaType], props.class)}>
      <h2>데브시스터즈는 최고의 인재를 찾고 있습니다.</h2>
      {props.title && (
        <>
          데브시스터즈에서는 능력있는{' '}
          {titleArray.length === linkArray.length &&
          props.title.includes(',') &&
          props.link.includes(',') ? (
            titleArray.map((title, idx) => (
              <>
                <a href={linkArray[idx].trim()} target="_blank">
                  {title.trim()}
                </a>
                <span>
                  {(titleArray.length > 2 && idx < titleArray.length - 2 && ', ') ||
                    (idx === titleArray.length - 2 && '와 ')}
                </span>
              </>
            ))
          ) : (
            <a href={props.link} target="_blank">
              {props.title}
            </a>
          )}
          를 찾고 있습니다.
          <br />
        </>
      )}
      자세한 내용은{' '}
      <a href="https://careers.devsisters.com/" target="_blank">
        채용 사이트
      </a>
      를 확인해주세요!
      {props.tags && (
        <div className={styles.tags}>
          {props.tags.split(',').map(tag => (
            <span key={`hiring-tag-${tag}`} className={styles.tag}>
              {tag.trim()}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default WeAreHiring;
