import React from 'react';
import classNames from 'classnames';

import * as styles from './Youtube.module.scss';

interface Props {
  'video-id'?: string;
  class?: string;
  width?: number;
  height?: number;
}

const Youtube: React.FC<Props> = (props: Props) => {
  const width = props.width || 640;
  const height = props.height || 480;
  return (
    <div className={classNames(styles.youtube, props.class)} style={{ width: `${width}px` }}>
      <div style={{ paddingTop: `${(height / width) * 100}%` }} />
      <iframe
        src={`https://www.youtube.com/embed/${props['video-id']}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export default Youtube;
